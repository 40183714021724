import { createSelector } from '@reduxjs/toolkit'
import type { Feature } from '~api'
import type { RootState } from '~data/store'

export const authSelector = (state: RootState) => state.auth
export const userSelector = createSelector(authSelector, auth => auth.user)
export const userIdSelector = createSelector(authSelector, auth => auth.user.id)
export const isAuthenticatedSelector = createSelector(userSelector, user => user.isAuthorized)
export const isSuspendedSelector = createSelector(userSelector, user => user.isSuspended)
export const isGuestSelector = createSelector(userSelector, user => user.isGuest)
export const sessionExpiredSelector = createSelector(userSelector, user => user.sessionExpired)
export const userFeaturesSelector = createSelector(authSelector, auth => auth.user.features)
export const userHasFeatureSelector = createSelector(
  userSelector,
  (_state: RootState, feature: Feature) => feature,
  (user, feature) => user.features.includes(feature),
)
export const makeUserHasFeatureSelector = (feature: Feature) => (state: RootState) => userHasFeatureSelector(state, feature)

/** User is admin selector */
export const isAdminSelector = createSelector(userSelector, user => user.isAdmin)
